import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage/LandingPage';
import AddCandidatePage from './pages/AddCandidate/AddCandidate';
import SearchCandidatePage from './pages/SearchCandidate/SearchCandidate';
import CandidatesPage from './pages/Candidates/Candidates';
import JobsPage from './pages/JobsPage/JobsPage';
import AdminPage from './pages/AdminPage/AdminPage';
import AddJobPage from './pages/AddJob/AddJob';
import AdminCandidatePage from './pages/AdminCandidate/AdminCandidatePage';
import AdminJobPage from './pages/AdminJob/AdminJobPage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/addcandidate" element={<AddCandidatePage />} />
          <Route path="/searchcandidate" element={<SearchCandidatePage />} />
          <Route path="/candidate" element={<CandidatesPage />} /> 
          <Route path="/jobs" element={<JobsPage />} />
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/addjob" element={<AddJobPage />} />
          <Route path="/admincandidate" element={<AdminCandidatePage />} />
          <Route path="/adminjob" element={<AdminJobPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
