import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import Layout from '../../components/Layout/Layout';
import './AdminCandidatePage.css';

// AWS SDK configuration
AWS.config.update({
  region: 'ap-southeast-1',
  accessKeyId: 'AKIA5FTY7JVW6TPTXL52', // Replace with your actual access key ID
  secretAccessKey: 'nrFNT2Z+p8mgMRqU7YR9vPrTWtr2w0U5RrqDIodE' // Replace with your actual secret access key
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const AdminCandidatePage = () => {
  const [candidates, setCandidates] = useState([]);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const params = {
          TableName: 'icondigitalsandpitdb'
        };
        const result = await dynamoDB.scan(params).promise();
        const fetchedCandidates = result.Items.map(item => ({
          id: item.candidate_id,
          name: item.name,
          expertise: item.expertise,
          fileName: item.resumeraw,
          fileUrl: item.resumeurl,
        }));
        setCandidates(fetchedCandidates);
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };

    fetchCandidates();
  }, []);

  const deleteCandidate = async (candidateId) => {
    try {
      console.log('Attempting to delete candidate with ID:', candidateId);

      // Create form data
      const formData = new FormData();
      formData.append('candidate_id', candidateId);

      // Send delete request to the FastAPI endpoint using form-data
      const response = await axios.post('https://sandpitapi.icondigital.ai/delete-candidate/', formData);
      console.log('Delete API response:', response.data);

      // Remove candidate from state
      setCandidates(candidates.filter(candidate => candidate.id !== candidateId));

      // Show success message
      window.alert('Candidate successfully deleted.');
    } catch (error) {
      console.error('Error deleting candidate:', error);
      window.alert('Failed to delete candidate. Please try again.');
    }
  };

  return (
    <Layout>
      <div className="admin-page">
        <h1>Admin Page</h1>
        <div className="candidate-table">
          <div className="candidate-header">
            <div className="header-item">Name</div>
            <div className="header-item">Expertise</div>
            <div className="header-item">CV Link</div>
            <div className="header-item">Actions</div>
          </div>
          <ul className="candidate-list">
            {candidates.map((candidate) => (
              <li key={candidate.id} className="candidate-item">
                <div className="candidate-info">{candidate.name}</div>
                <div className="candidate-info">{candidate.expertise}</div>
                <div className="candidate-info">
                  <a href={candidate.fileUrl} download>{candidate.fileName}</a>
                </div>
                <div className="candidate-info">
                  <button
                    className="delete-button"
                    onClick={() => deleteCandidate(candidate.id)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default AdminCandidatePage;
