import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import Layout from '../../components/Layout/Layout';
import './Candidates.css';

// AWS SDK configuration
AWS.config.update({
  region: 'ap-southeast-1',
  accessKeyId: 'AKIA5FTY7JVW6TPTXL52', // Replace with your actual access key ID
  secretAccessKey: 'nrFNT2Z+p8mgMRqU7YR9vPrTWtr2w0U5RrqDIodE' // Replace with your actual secret access key
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const Candidates = () => {
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [similarCandidates, setSimilarCandidates] = useState([]);

  useEffect(() => {
    const fetchCandidates = async () => {
      try {
        const params = {
          TableName: 'icondigitalsandpitdb'
        };
        const result = await dynamoDB.scan(params).promise();
        const fetchedCandidates = result.Items.map(item => ({
          id: item.candidate_id,
          name: item.name,
          expertise: item.expertise,
          fileName: item.resumeraw,
          fileUrl: item.resumeurl,
          similarCandidates: [], // Placeholder for similar candidates
          matchedRoles: [] // Placeholder for matched roles
        }));
        setCandidates(fetchedCandidates);
      } catch (error) {
        console.error('Error fetching candidates:', error);
      }
    };

    fetchCandidates();
  }, []);

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    setSimilarCandidates([]); // Clear similar candidates when a new candidate is selected
  };

  const handleFindSimilarCandidates = async () => {
    if (!selectedCandidate) return;

    try {
      // Create a FormData object to send the data as form-data
      const formPayload = new FormData();
      formPayload.append('candidate_id', selectedCandidate.id);

      // Call the backend API to find similar candidates
      const response = await fetch('https://sandpitapi.icondigital.ai/similar-candidates/', {
        method: 'POST',
        body: formPayload
      });

      if (!response.ok) {
        throw new Error('Failed to find similar candidates');
      }

      const result = await response.json();
      const similarCandidateIds = result.candidate_ids || [];

      // Fetch candidate details from DynamoDB based on similar candidate IDs
      const fetchedSimilarCandidates = await Promise.all(similarCandidateIds.map(async (id) => {
        const params = {
          TableName: 'icondigitalsandpitdb',
          Key: { candidate_id: id }  // Keep ID as a string to avoid precision loss
        };
        const candidateData = await dynamoDB.get(params).promise();
        const item = candidateData.Item;

        if (!item) {
          throw new Error(`Candidate with ID ${id} not found in DynamoDB`);
        }

        return {
          id: item.candidate_id,
          name: item.name,
          expertise: item.expertise,
          fileName: item.resumeraw,
          fileUrl: item.resumeurl
        };
      }));

      setSimilarCandidates(fetchedSimilarCandidates);
    } catch (error) {
      console.error('Error finding similar candidates:', error);
      alert('Failed to find similar candidates.');
    }
  };

  return (
    <Layout>
      <div className="Candidates">
        <div className="left-column">
          <h1>Candidates</h1>
          <div className="candidate-table">
            <div className="candidate-header">
              <div className="header-item">Name</div>
              <div className="header-item">Expertise</div>
              <div className="header-item">CV Link</div>
            </div>
            <ul className="candidate-list">
              {candidates.map((candidate) => (
                <li key={candidate.id} className="candidate-item" onClick={() => handleCandidateClick(candidate)}>
                  <div className="candidate-info clickable">{candidate.name}</div>
                  <div className="candidate-info">{candidate.expertise}</div>
                  <div className="candidate-info">
                    <a href={candidate.fileUrl} download>{candidate.fileName}</a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-column">
          {selectedCandidate ? (
            <>
              <h2>{selectedCandidate.name}</h2>
              <p><strong>Expertise:</strong> {selectedCandidate.expertise}</p>
              
              <button className="action-button" onClick={handleFindSimilarCandidates}>Find Similar Candidates</button>
              {similarCandidates.length > 0 && (
                <div className="results-box">
                  <h2>Similar Candidates</h2>
                  <ul className="candidate-list">
                    {similarCandidates.map((candidate) => (
                      <li key={candidate.id} className="candidate-item">
                        <div className="candidate-info">{candidate.name}</div>
                        <div className="candidate-info">{candidate.expertise}</div>
                        <div className="candidate-info">
                          <a href={candidate.fileUrl} download>{candidate.fileName}</a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <p>Click on a candidate's name to see details.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Candidates;
