import React from 'react';
import Layout from '../../components/Layout/Layout';
import './AdminPage.css';

const AdminPage = () => {
  return (
    <Layout>
      <div className="hero-section">
        <h1>Admin Page</h1>
        <p>Manage your records and access API documentation</p>
      </div>

      <div className="content-section">
        <div className="features-instructions-container">
          {/* Left Box - Record Administration */}
          <div className="features-box">
            <h2>Record Administration</h2>
            <p>Currently, only the delete function is supported for candidates and jobs.</p>
            <p>Docx and PDF format CVs are currently supported in our serverless sandpit environment. As we scale to production, .doc format will also be supported since we'll need a dedicate server to handle the conversions from .doc to .docx</p>
          </div>
          
          {/* Right Box - API Documentation */}
          <div className="instructions-box">
            <h2>API Documentation</h2>
            <table className="api-table">
              <tbody>
                <tr>
                  <td><strong>Search Candidate API:</strong></td>
                  <td><a href="https://sandpitapi.icondigital.ai/search-candidate/" target="_blank" rel="noopener noreferrer">sandpitapi.icondigital.ai/process-file/</a></td>
                </tr>
                <tr>
                  <td><strong>Match Candidates API:</strong></td>
                  <td><a href="https://sandpitapi.icondigital.ai/match-candidates/" target="_blank" rel="noopener noreferrer">sandpitapi.icondigital.ai/process-job/</a></td>
                </tr>
                <tr>
                  <td><strong>Similar Candidates API:</strong></td>
                  <td><a href="https://sandpitapi.icondigital.ai/similar-candidate/" target="_blank" rel="noopener noreferrer">sandpitapi.icondigital.ai/search-candidate/</a></td>
                </tr>
              </tbody>
            </table>
            <p>Please see the <a href="https://sandpitapi.icondigital.ai/docs" target="_blank" rel="noopener noreferrer">API Documentation</a> to explore the APIs further.</p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AdminPage;
