import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logo.png'; 
import './Navbar.css';

const Navbar = () => {
  // State to track the visibility of dropdowns
  const [jobsDropdown, setJobsDropdown] = useState(false);
  const [candidatesDropdown, setCandidatesDropdown] = useState(false);
  const [adminDropdown, setAdminDropdown] = useState(false);

  return (
    <nav className="nav">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo-image" />
        <div className="logo-text">Icon Digital Sandpit</div>
      </div>
      <div className="nav-links">
        <Link to="/">Home</Link>

        {/* Jobs Dropdown */}
        <div 
          className="dropdown" 
          onMouseEnter={() => setJobsDropdown(true)} 
          onMouseLeave={() => setJobsDropdown(false)}
        >
          <Link to="/jobs">Jobs</Link>
          {jobsDropdown && (
            <div className="dropdown-content">
              <Link to="/addjob">Add Job</Link>
            </div>
          )}
        </div>

        {/* Candidates Dropdown */}
        <div 
          className="dropdown" 
          onMouseEnter={() => setCandidatesDropdown(true)} 
          onMouseLeave={() => setCandidatesDropdown(false)}
        >
          <Link to="/candidate">Candidates</Link>
          {candidatesDropdown && (
            <div className="dropdown-content">
              <Link to="/addcandidate">Add Candidate</Link>
              <Link to="/searchcandidate">Search Candidate</Link>
            </div>
          )}
        </div>

        {/* Admin Dropdown */}
        <div 
          className="dropdown" 
          onMouseEnter={() => setAdminDropdown(true)} 
          onMouseLeave={() => setAdminDropdown(false)}
        >
          <Link to="/admin">Admin</Link>
          {adminDropdown && (
            <div className="dropdown-content dropdown-content-right">
              <Link to="/admincandidate">Manage Candidates</Link>
              <Link to="/adminjob">Manage Jobs</Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
