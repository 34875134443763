import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './AddCandidate.css';

const AddCandidate = () => {
  const [formData, setFormData] = useState({
    name: '',
    expertise: [],
    resume: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleExpertiseChange = (e) => {
    setFormData({
      ...formData,
      expertise: e.target.value // Set the selected expertise
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const allowedExtensions = ['pdf', 'docx'];

    if (!allowedExtensions.includes(fileExtension)) {
      alert('Only .pdf and .docx files are allowed.');
      setFormData({
        ...formData,
        resume: null
      });
      e.target.value = null; // Reset the file input
    } else {
      setFormData({
        ...formData,
        resume: file
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.resume) {
      alert('Please upload a valid resume.');
      return;
    }

    const data = new FormData();
    data.append('name', formData.name);
    data.append('expertise', formData.expertise);
    data.append('file', formData.resume);

    try {
      console.log('Sending data to the Python API...');
      const response = await fetch('https://sandpitapi.icondigital.ai/process-file/', {
        method: 'POST',
        body: data
      });

      const result = await response.json();
      if (response.ok) {
        console.log('File processed and data stored successfully:', result);
        alert('Candidate added successfully!');
      } else {
        console.error('Error processing file:', result.error);
        alert('Failed to add candidate. ' + result.error);
      }
    } catch (error) {
      console.error('Error adding candidate:', error);
      alert('Failed to add candidate.');
    }
  };

  return (
    <Layout>
      <div className="Add-Candidate">
        <h1>Add Candidate</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="expertise">Expertise</label>
            <div className="custom-multi-select">
              <label>
                <span>SAP</span>
                <input
                  type="radio"
                  name="expertise"
                  value="SAP"
                  checked={formData.expertise === 'SAP'}
                  onChange={handleExpertiseChange}
                />
              </label>
              <label>
                <span>Salesforce</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Salesforce"
                  checked={formData.expertise === 'Salesforce'}
                  onChange={handleExpertiseChange}
                />
              </label>
              <label>
                <span>Oracle</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Oracle"
                  checked={formData.expertise === 'Oracle'}
                  onChange={handleExpertiseChange}
                />
              </label>
              <label>
                <span>Workday</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Workday"
                  checked={formData.expertise === 'Workday'}
                  onChange={handleExpertiseChange}
                />
              </label>
              <label>
                <span>Microsoft</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Microsoft"
                  checked={formData.expertise === 'Microsoft'}
                  onChange={handleExpertiseChange}
                />
              </label>
              <label>
                <span>Other</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Other"
                  checked={formData.expertise === 'Other'}
                  onChange={handleExpertiseChange}
                />
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="resume">Upload Resume</label>
            <input
              type="file"
              id="resume"
              name="resume"
              accept=".pdf,.docx" // Restrict file types
              onChange={handleFileChange}
              required
            />
          </div>
          <button type="submit">Add Candidate</button>
        </form>
      </div>
    </Layout>
  );
};

export default AddCandidate;
