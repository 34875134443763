import React, { useState } from 'react';
import AWS from 'aws-sdk';
import Layout from '../../components/Layout/Layout';
import './SearchCandidate.css';

// AWS SDK configuration
AWS.config.update({
  region: 'ap-southeast-1',
  accessKeyId: 'AKIA5FTY7JVW6TPTXL52', // Replace with your actual access key ID
  secretAccessKey: 'nrFNT2Z+p8mgMRqU7YR9vPrTWtr2w0U5RrqDIodE' // Replace with your actual secret access key
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const SearchCandidate = () => {
  const [formData, setFormData] = useState({
    expertise: '',
    candidateDescription: ''
  });
  const [candidates, setCandidates] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleExpertiseChange = (e) => {
    setFormData({
      ...formData,
      expertise: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create a FormData object to send the data as form-data
      const formPayload = new FormData();
      formPayload.append('expertise', formData.expertise);
      formPayload.append('candidateDescription', formData.candidateDescription);

      // Call the backend API to search candidates
      const response = await fetch('https://sandpitapi.icondigital.ai/search-candidate/', {
        method: 'POST',
        body: formPayload
      });

      if (!response.ok) {
        throw new Error('Failed to search candidates');
      }

      const result = await response.json();
      const candidateIds = result.candidate_ids || [];

      // Fetch candidate details from DynamoDB/S3 based on candidate IDs
      const fetchedCandidates = await Promise.all(candidateIds.map(async (id) => {
        console.log(`Querying DynamoDB for candidate_id: ${id}, type: ${typeof id}`);

        const params = {
          TableName: 'icondigitalsandpitdb',
          Key: { candidate_id: id }  // Keep ID as a string to avoid precision loss
        };
        const candidateData = await dynamoDB.get(params).promise();
        const item = candidateData.Item;

        if (!item) {
          throw new Error(`Candidate with ID ${id} not found in DynamoDB`);
        }

        return {
          id: item.candidate_id,
          name: item.name,
          expertise: item.expertise,
          fileName: item.resumeraw,
          fileUrl: item.resumeurl,
          similarCandidates: [], // Placeholder for similar candidates
          matchedRoles: [] // Placeholder for matched roles
        };
      }));

      setCandidates(fetchedCandidates);
    } catch (error) {
      console.error('Error searching candidates:', error);
      alert('Failed to search candidates.');
    }
  };

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
  };

  return (
    <Layout>
      <div className="Search-Candidate">
        <div className="left-column">
          <h1>Search Candidates</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="expertise">Expertise</label>
              <div className="custom-multi-select">
                <label>
                  <span>SAP</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="SAP"
                    checked={formData.expertise === 'SAP'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
                <label>
                  <span>Salesforce</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="Salesforce"
                    checked={formData.expertise === 'Salesforce'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
                <label>
                  <span>Oracle</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="Oracle"
                    checked={formData.expertise === 'Oracle'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
                <label>
                  <span>Workday</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="Workday"
                    checked={formData.expertise === 'Workday'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
                <label>
                  <span>Microsoft</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="Microsoft"
                    checked={formData.expertise === 'Microsoft'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
                <label>
                  <span>Other</span>
                  <input
                    type="radio"
                    name="expertise"
                    value="Other"
                    checked={formData.expertise === 'Other'}
                    onChange={handleExpertiseChange}
                    required
                  />
                </label>
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="candidateDescription">Candidate Description</label>
              <textarea
                id="candidateDescription"
                name="candidateDescription"
                value={formData.candidateDescription}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Search Candidates</button>
          </form>
        </div>
        <div className="right-column">
          {candidates.length > 0 && (
            <div className="candidate-table">
              <h2>Search Results</h2>
              <div className="candidate-header">
                <div className="header-item">Name</div>
                <div className="header-item">Expertise</div>
                <div className="header-item">CV Link</div>
              </div>
              <ul className="candidate-list">
                {candidates.map((candidate) => (
                  <li key={candidate.id} className="candidate-item" onClick={() => handleCandidateClick(candidate)}>
                    <div className="candidate-info clickable">{candidate.name}</div>
                    <div className="candidate-info">{candidate.expertise}</div>
                    <div className="candidate-info">
                      <a href={candidate.fileUrl} download>{candidate.fileName}</a>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {selectedCandidate && (
            <div className="candidate-details">
              <h2>{selectedCandidate.name}</h2>
              <p><strong>Expertise:</strong> {selectedCandidate.expertise}</p>
              
              <button className="action-button">Find Similar Candidates</button>
              <div className="results-box">
                {selectedCandidate.similarCandidates.length > 0 ? (
                  selectedCandidate.similarCandidates.map((name, index) => (
                    <p key={index}>{name}</p>
                  ))
                ) : (
                  <p>No similar candidates found.</p>
                )}
              </div>
              
              <button className="action-button">Match to Roles</button>
              <div className="results-box">
                {selectedCandidate.matchedRoles.length > 0 ? (
                  selectedCandidate.matchedRoles.map((role, index) => (
                    <p key={index}>{role}</p>
                  ))
                ) : (
                  <p>No matched roles found.</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default SearchCandidate;
