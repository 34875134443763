import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import axios from 'axios';
import Layout from '../../components/Layout/Layout';
import './AdminJobPage.css';

// AWS SDK configuration
AWS.config.update({
  region: 'ap-southeast-1',
  accessKeyId: 'AKIA5FTY7JVW6TPTXL52', // Replace with your actual access key ID
  secretAccessKey: 'nrFNT2Z+p8mgMRqU7YR9vPrTWtr2w0U5RrqDIodE' // Replace with your actual secret access key
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const AdminJobPage = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const params = {
          TableName: 'icondigitalsandpitdb2'
        };
        const result = await dynamoDB.scan(params).promise();
        const fetchedJobs = result.Items.map(item => ({
          id: item.job_id,
          title: item.jobTitle, // Assuming the correct field name is 'jobTitle'
          expertise: item.expertise,
          description: item.description.slice(0, 100) + (item.description.length > 100 ? '...' : ''),
        }));
        setJobs(fetchedJobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const deleteJob = async (jobId) => {
    try {
      console.log('Attempting to delete job with ID:', jobId);

      // Create form data
      const formData = new FormData();
      formData.append('job_id', jobId);

      // Send delete request to the FastAPI endpoint using form-data
      const response = await axios.post('https://sandpitapi.icondigital.ai/delete-job/', formData);
      console.log('Delete API response:', response.data);

      // Remove job from state
      setJobs(jobs.filter(job => job.id !== jobId));

      // Show success message
      window.alert('Job successfully deleted.');
    } catch (error) {
      console.error('Error deleting job:', error);
      window.alert('Failed to delete job. Please try again.');
    }
  };

  return (
    <Layout>
      <div className="admin-page">
        <h1>Admin Job Page</h1>
        <div className="job-table">
          <div className="job-header">
            <div className="header-item">Job Title</div>
            <div className="header-item">Expertise</div>
            <div className="header-item">Description</div>
            <div className="header-item">Actions</div>
          </div>
          <ul className="job-list">
            {jobs.map((job) => (
              <li key={job.id} className="job-item">
                <div className="job-info">{job.title}</div>
                <div className="job-info">{job.expertise}</div>
                <div className="job-info">{job.description}</div>
                <div className="job-info">
                  <button
                    className="delete-button"
                    onClick={() => deleteJob(job.id)}
                  >
                    Delete
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default AdminJobPage;
