import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import './AddJob.css';

const AddJob = () => {
  const [formData, setFormData] = useState({
    jobTitle: '',
    expertise: '',
    jobDescription: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleExpertiseChange = (e) => {
    setFormData({
      ...formData,
      expertise: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Create a FormData object to send the data as form-data
      const formPayload = new FormData();
      formPayload.append('jobTitle', formData.jobTitle);
      formPayload.append('expertise', formData.expertise);
      formPayload.append('jobDescription', formData.jobDescription);

      // Call the backend API
      const response = await fetch('https://sandpitapi.icondigital.ai/process-job/', {
        method: 'POST',
        body: formPayload
      });

      if (!response.ok) {
        throw new Error('Failed to add job');
      }

      const result = await response.json();
      console.log('Job added successfully:', result);

      alert('Job added successfully!');
    } catch (error) {
      console.error('Error adding job:', error);
      alert('Failed to add job.');
    }
  };

  return (
    <Layout>
      <div className="Add-Job">
        <h1>Add Job</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="jobTitle">Job Title</label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="expertise">Expertise</label>
            <div className="custom-multi-select">
              <label>
                <span>SAP</span>
                <input
                  type="radio"
                  name="expertise"
                  value="SAP"
                  checked={formData.expertise === 'SAP'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
              <label>
                <span>Salesforce</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Salesforce"
                  checked={formData.expertise === 'Salesforce'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
              <label>
                <span>Oracle</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Oracle"
                  checked={formData.expertise === 'Oracle'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
              <label>
                <span>Workday</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Workday"
                  checked={formData.expertise === 'Workday'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
              <label>
                <span>Microsoft</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Microsoft"
                  checked={formData.expertise === 'Microsoft'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
              <label>
                <span>Other</span>
                <input
                  type="radio"
                  name="expertise"
                  value="Other"
                  checked={formData.expertise === 'Other'}
                  onChange={handleExpertiseChange}
                  required
                />
              </label>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="jobDescription">Job Description</label>
            <textarea
              id="jobDescription"
              name="jobDescription"
              value={formData.jobDescription}
              onChange={handleChange}
              required
            />
          </div>
          <button type="submit">Add Job</button>
        </form>
      </div>
    </Layout>
  );
};

export default AddJob;
