import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/Layout/Layout';
import './LandingPage.css';

const LandingPage = () => {
  return (
    <Layout>
      <div className="hero-section">
        <h1>Icon Digital Sandpit</h1>
        <p>A.I. search demonstration</p>
      </div>

      <div className="content-section">
        <div className="features-instructions-container">
          <div className="features-box">
            <h2>Key Features</h2>
            <ul>
              <li><strong>Candidate Matching:</strong> Our advanced A.I. search functionality can find you the right talent by leveraging a semantic understanding of the candidate.</li>
              <li><strong>Similar Profiles:</strong> Run a similarity search and find candidates that have similar skills and experiences.</li>
              <li><strong>Multi Language Support:</strong> Our A.I. understands all languages, and can search across different languages.</li>
            </ul>
            <h2>Upcoming Features</h2>
            <ul>
              <li><strong>Expertise Weighting:</strong> Currently we have not assigned any weights to the Expertise selection. This will be added in a future release.</li>
              <li><strong>Scoring Mechanism:</strong> Currently we do not have a scoring scale to indicate how close the matches are. This will be developed in the near future.</li>
              <li><strong>SAP Module Fine Tuning:</strong> Our A.I. will learn to understand the nuances between the SAP Modules even better.</li>
            </ul>
          </div>
          <div className="instructions-box">
            <h2>Instructions</h2>
            <ol>
              <li><strong>Add candidates:</strong> Enter some basic details</li> 
              <li><strong>Add a job or candidate description:</strong> Upload a job description or better yet, describe the candidate you are looking for</li>
              <li><strong>Test the A.I. match capabilities:</strong> Match to the job description, to the candidate description, or find similar candidates</li>
            </ol>
          </div>
        </div>
        
        <div className="button-container">
          <Link to="/addcandidate">
            <button className="cta-button">Add Candidates</button>
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default LandingPage;
