import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
import Layout from '../../components/Layout/Layout';
import './JobsPage.css';

// AWS SDK configuration
AWS.config.update({
  region: 'ap-southeast-1',
  accessKeyId: 'AKIA5FTY7JVW6TPTXL52', // Replace with your actual access key ID
  secretAccessKey: 'nrFNT2Z+p8mgMRqU7YR9vPrTWtr2w0U5RrqDIodE' // Replace with your actual secret access key
});

const dynamoDB = new AWS.DynamoDB.DocumentClient();

const Jobs = () => {
  const [jobs, setJobs] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [matchedCandidates, setMatchedCandidates] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const params = {
          TableName: 'icondigitalsandpitdb2' // Assuming your jobs are stored in this table
        };
        const result = await dynamoDB.scan(params).promise();
        const fetchedJobs = result.Items.map(item => ({
          id: item.job_id,
          jobTitle: item.jobTitle,
          expertise: item.expertise,
          description: item.description, // Fetching the job description
          similarJobs: [], // Placeholder for similar jobs
          matchedCandidates: [] // Placeholder for matched candidates
        }));
        setJobs(fetchedJobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const handleJobClick = (job) => {
    setSelectedJob(job);
    setMatchedCandidates([]); // Clear matched candidates when a new job is selected
  };

  const handleFindCandidates = async () => {
    if (!selectedJob) return;

    try {
      // Create a FormData object to send the data as form-data
      const formPayload = new FormData();
      formPayload.append('job_id', selectedJob.id);

      // Call the backend API to match candidates
      const response = await fetch('https://sandpitapi.icondigital.ai/match-candidates/', {
        method: 'POST',
        body: formPayload
      });

      if (!response.ok) {
        throw new Error('Failed to match candidates');
      }

      const result = await response.json();
      const candidateIds = result.candidate_ids || [];

      // Fetch candidate details from DynamoDB/S3 based on candidate IDs
      const fetchedCandidates = await Promise.all(candidateIds.map(async (id) => {
        const params = {
          TableName: 'icondigitalsandpitdb',
          Key: { candidate_id: id }  // Keep ID as a string to avoid precision loss
        };
        const candidateData = await dynamoDB.get(params).promise();
        const item = candidateData.Item;

        if (!item) {
          throw new Error(`Candidate with ID ${id} not found in DynamoDB`);
        }

        return {
          id: item.candidate_id,
          name: item.name,
          expertise: item.expertise,
          fileName: item.resumeraw,
          fileUrl: item.resumeurl
        };
      }));

      setMatchedCandidates(fetchedCandidates);
    } catch (error) {
      console.error('Error finding candidates:', error);
      alert('Failed to find candidates.');
    }
  };

  return (
    <Layout>
      <div className="Jobs">
        <div className="left-column">
          <h1>Jobs</h1>
          <div className="job-table">
            <div className="job-header">
              <div className="header-item">Job Title</div>
              <div className="header-item">Expertise</div>
            </div>
            <ul className="job-list">
              {jobs.map((job) => (
                <li key={job.id} className="job-item" onClick={() => handleJobClick(job)}>
                  <div className="job-info clickable">{job.jobTitle}</div>
                  <div className="job-info">{job.expertise}</div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="right-column">
          {selectedJob ? (
            <>
              <h2>{selectedJob.jobTitle}</h2>
              <p><strong>Expertise:</strong> {selectedJob.expertise}</p>
              <p><strong>Description:</strong> {selectedJob.description}</p>
              
              <button className="action-button" onClick={handleFindCandidates}>Find Candidates</button>
              {matchedCandidates.length > 0 && (
                <div className="results-box">
                  <h2>Matched Candidates</h2>
                  <ul className="candidate-list">
                    {matchedCandidates.map((candidate) => (
                      <li key={candidate.id} className="candidate-item">
                        <div className="candidate-info">{candidate.name}</div>
                        <div className="candidate-info">{candidate.expertise}</div>
                        <div className="candidate-info">
                          <a href={candidate.fileUrl} download>{candidate.fileName}</a>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          ) : (
            <p>Click on a job title to see details.</p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Jobs;
